import React from "react";
import DefaultIntegrationCard from "../Cards/DefaultIntegrationCard";
import Button, { ButtonVarint } from "../../components/Button";
import NpAppsWarning from "./NoAppsWarning";
import { useActions } from "../../lib/overmind";
import { formatDate } from "../../lib/date";

const ConnectedApps = ({ apps }) => {
  const actions = useActions().integrations;
  if (!apps?.length) return <NpAppsWarning />;

  const onDisconnect = ({ id, name }) => {
    const didConfirm = confirm(`Are you sure you want to disconnect ${name}?`);
    if (!didConfirm) return;
    actions.disconnectApp(id);
  };

  return (
    <div>
      {apps.map(({ id, name, description, connected_on }) => {
        const body = (
          <div>
            <p>{description}</p>
            <span className="fs-13 text-secondary">
              Connected: {formatDate(connected_on)}
            </span>
          </div>
        );

        return (
          <DefaultIntegrationCard title={name} body={body}>
            <Button
              variant={ButtonVarint.dangerOutline}
              onClick={() => {
                onDisconnect({ id, name });
              }}
            >
              Disconnect
            </Button>
          </DefaultIntegrationCard>
        );
      })}
    </div>
  );
};

export default ConnectedApps;
