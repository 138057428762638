import React from "react";
import { LightspeedRSeriesAppIcon } from "../IntegrationAppIcon";
import ExternalLink from "../components/ExternalLink";
import { formatDate, formatRelativeDate } from "../lib/date";
import { usePost } from "../lib/api";

const Check = <i className="bi-check-circle-fill text-success" />;
const Uncheck = <i className="bi-x-circle-fill text-secondary" />;

const MessageWorkorderCardBody = ({ id, workorder }) => {
  const [localWorkorder, setLocalWorkorder] = React.useState(workorder);
  const [post, isLoading, error, resp] = usePost();

  if (!workorder) {
    return (
      <>
        <b>Lightspeed Workorder</b>
        <div>Missing workorder data. Unable to render.</div>
      </>
    );
  }

  const onClick = async () => {
    post(`/shop/{exid}/lightspeed/workorder/${id}/refresh`);
  };

  React.useEffect(() => {
    if (resp) {
      setLocalWorkorder(resp.data?.data);
    }
  }, [resp]);

  return (
    <>
      <div className="d-flex gap-2 ms-2">
        <LightspeedRSeriesAppIcon />
        <span className="flex-grow-1 d-block fs-14 d-flex flex-column">
          <b>Lightspeed Workorder</b>
          <ExternalLink
            className="fs-14"
            target="_blank"
            rel="noreferrer"
            href={`https://us.merchantos.com/?name=workbench.views.beta_workorder&form_name=view&id=${workorder.workorder_id}`}
          >
            Open #{localWorkorder.workorder_id}
          </ExternalLink>
        </span>
      </div>

      <table className="table w-100 fs-14 mb-1">
        <tbody>
          <tr>
            <td>Status</td>
            <td>
              <span
                className="badge px-2"
                style={{
                  backgroundColor: localWorkorder.status_color || "#E3E3E3",
                }}
              >
                {localWorkorder.status_name}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex">
                <div className="me-2">Warranty</div>
                <div className="mx-auto">
                  {localWorkorder.warranty ? Check : Uncheck}
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex">
                <div className="me-2">Save Parts</div>
                <div className="mx-auto">
                  {localWorkorder.save_parts ? Check : Uncheck}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="me-2 text-nowrap">Date In</div>
            </td>
            <td className="text-nowrap">
              {formatDate(localWorkorder.date_in)}
            </td>
          </tr>
          <tr>
            <td>Due</td>
            <td className="text-nowrap">
              {formatDate(localWorkorder.date_due)}
            </td>
          </tr>
          <tr>
            <td>Employee</td>
            <td>{localWorkorder.employee_name}</td>
          </tr>
          <tr>
            <td className="text-nowrap">Receipt Note</td>
            <td>{localWorkorder.receipt_note}</td>
          </tr>
          <tr>
            <td className="text-nowrap">Internal Note</td>
            <td>{localWorkorder.internal_note}</td>
          </tr>
        </tbody>
      </table>
      <span className="fs-12 text-secondary ps-2">
        Last refreshed{" "}
        {formatRelativeDate(localWorkorder.date_last_updated).dateString}
        <button
          disabled={isLoading}
          type="button"
          className="btn btn-link ms-2 fs-12 p-0 text-secondary"
          onClick={onClick}
        >
          <span
            className="spinner-border fs-12"
            style={{ width: "12px", height: "12px" }}
            role="status"
            hidden={!isLoading}
          />
          <i hidden={isLoading} className={`bi-arrow-repeat `} /> Refresh
        </button>
      </span>
    </>
  );
};

const MessageWorkorderCard = ({ message }) => {
  const context = message.meta?.context;

  return (
    <div className="card ms-auto" style={{ width: "500px" }}>
      <div className="card-body">
        <MessageWorkorderCardBody id={message.sid} workorder={context} />
      </div>
    </div>
  );
};

export default MessageWorkorderCard;
