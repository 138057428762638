import React from "react";
import LabeledField from "./LabeledField";

const DateTimeControl = ({ id, label, ...props }) => {
  return (
    <LabeledField
      name={id}
      id={id}
      type="datetime-local"
      label={label}
      {...props}
    />
  );
};

export default DateTimeControl;
