import React from "react";
import { ContactSupportCta } from "../lib/ikeono";

import { snakeToTitleCase } from "../lib/stringUtil";
import LightspeedDisconnectedWarning from "./Lightspeed/LightspeedDisconnectedWarning";

const isLightspeedDisconnectedError = (response) => {
  const message = response?.data?.message;
  if (!message) return;
  if (message.includes("Lightspeed API needs to be connected.")) return true;
};

const APIResponseError = ({
  children,
  errorKeyTitles,
  className,
  ...props
}) => {
  const response = props.response?.response || props.response;

  if (!response) return null;

  if (isLightspeedDisconnectedError(response)) {
    return <LightspeedDisconnectedWarning />;
  }

  if (response.status === 200) {
    return null;
  }

  const klass = `alert alert-danger d-inline-block text-start ${className} fs-15`;

  if (response.status === 400) {
    const { data } = response;
    return (
      <div className={klass}>
        {Object.keys(data).map((key) => {
          const title =
            (errorKeyTitles && errorKeyTitles[key]) || snakeToTitleCase(key);

          if (Array.isArray(data[key])) {
            return (
              <>
                <b className="mt-1">{title}</b>
                <ul className="mb-1">
                  {data[key].map((item) => (
                    <li key={1}>{item}</li>
                  ))}
                </ul>
              </>
            );
          }
          return Object.keys(data[key]).map((childKey) => {
            return (
              <>
                <b className="mt-1">{`${title} ${snakeToTitleCase(
                  childKey
                )}`}</b>
                <ul className="mb-1">
                  {data[key][childKey].map((item) => (
                    <li key={1}>{item}</li>
                  ))}
                </ul>
              </>
            );
          });
        })}
        <span className="mt-3">
          <ContactSupportCta />
        </span>
        {children}
      </div>
    );
  }

  const message = response?.data?.message || response?.data?.detail;
  if (message) {
    return (
      <div className={klass}>
        {message}
        {children}
      </div>
    );
  }

  if (response.source === "ik") {
    return (
      <div className={klass}>
        {response.message}
        {children}
      </div>
    );
  }

  return (
    <div className={klass}>
      There was an unknown error. <ContactSupportCta />
      {children}
    </div>
  );
};

export default APIResponseError;
