import React from "react";
import Modal, { hideModal, ModalVariant } from "../../Modal";
import LightspeedCreateWorkorderForm from "./LightspeedCreateWorkorderForm";
import { useState } from "../../../lib/overmind";
import { LightspeedRSeriesAppIcon } from "../../../IntegrationAppIcon";
import LightspeedCreateWorkorderUpgrade from "./LightspeedCreateWorkorderUpgrade";
import NoLightspeedContactWarning from "./NoLightspeedContactWarning";

const LightspeedCreateWorkorderModalId = "LightspeedCreateWorkorderModal";

const ModalTitle = () => (
  <div className="d-flex gap-3 justify-content-center align-items-center">
    <LightspeedRSeriesAppIcon />
    <div className="d-flex flex-column">
      <b className="fs-15" style={{ lineHeight: "20px" }}>
        Create a Lightspeed Workorder
      </b>
      <span className="fs-14">
        The workorder is attached with this conversation.
      </span>
    </div>
  </div>
);

const LightspeedCreateWorkorderModal = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const state = useState();
  const { contact } = state.activeThread;
  const needsAccountUpgrade =
    state.account.data.config.portal.create_lightspeed_workorders
      .needs_account_upgrade;
  const vendorId = state.activeThread.contact?.vendor_id;

  const modalSize =
    needsAccountUpgrade || !vendorId ? ModalVariant.lg : ModalVariant.md;

  return (
    <Modal
      id={LightspeedCreateWorkorderModalId}
      variant={modalSize}
      title={<ModalTitle />}
    >
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: "450px" }}>
          <LightspeedCreateWorkorderForm
            disabled={!contact?.vendor_id}
            showUpgrade={needsAccountUpgrade}
            customerPhoneNumber={contact?.mobile_phone_number}
            customerVendorId={vendorId}
            isLoading={(isLoading_) => {
              setIsLoading(isLoading_);
            }}
            onSubmit={() => {
              hideModal(LightspeedCreateWorkorderModalId);
            }}
          />
        </div>
        <div className="ms-4">
          <NoLightspeedContactWarning
            hidden={!!vendorId || needsAccountUpgrade}
          />
          <LightspeedCreateWorkorderUpgrade
            hidden={!needsAccountUpgrade || isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LightspeedCreateWorkorderModal;
