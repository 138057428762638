import React from "react";
import { Route, Routes } from "react-router";
import PaymentDetail from "./PaymentDetail";
import PaymentTable from "./PaymentTable";
import CreatePaymentRequestModal from "./CreatePaymentRequest/CreatePaymentRequestModal";

const Payments = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<PaymentTable />} />
      </Routes>
      <PaymentDetail />
      <CreatePaymentRequestModal />
    </>
  );
};

export default Payments;
