/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteList from "../components/InfiniteList";
import { formatRelativeDateNoTime } from "../lib/date";
import { useActions, useState } from "../lib/overmind";
import InboxRow from "./InboxRow";
import Loader from "../components/Loader";
import InboxThreadsZeroState from "./InboxThreadsZeroState";
import InboxThreadListError from "./InboxThreadListError";
import { isMobileDevice } from "../lib/device";

const InboxRowWrapper = ({ style, index, inboxName }) => {
  const state = useState().activeInbox;
  const actions = useActions().activeInbox;
  const activeThreadState = useState().activeThread;
  const thread = state.threads[index];
  const navigate = useNavigate();

  if (!thread) return null;

  return (
    <div
      style={style}
      data-index={index}
      onClick={() => {
        navigate(`/inbox/${inboxName}/${thread.id}`, {
          replace: !isMobileDevice(),
        });
        actions.markThreadAsRead(thread.id);
      }}
    >
      <InboxRow
        style={style}
        threadId={thread.id}
        firstName={thread.contact?.first_name}
        lastName={thread.contact?.last_name}
        receiverId={thread.receiver_id}
        snippet={thread.snippet}
        hasNewMessage={thread.has_unread_messages}
        dateLastUpdated={formatRelativeDateNoTime(thread.date_last_interaction)}
        messageType={thread.thread_type}
        isActive={thread.id === activeThreadState.conversationId}
        tags={thread.inboxes}
        status={thread.status_id}
        threadType={thread.thread_type}
      />
    </div>
  );
};

const InboxThreadList = ({ heightOffset, includeThreadId, inboxName }) => {
  const actions = useActions().activeInbox;
  const state = useState().activeInbox;
  const activeThreadState = useState().activeThread;

  const filter = { inboxName };

  const load = async () => {
    await actions.clearCurrentThread();
    await actions.updateFilter({ includeThreadId, ...filter });
    if (!activeThreadState.conversationId && !isMobileDevice()) {
      actions.navigateToFirstThread();
    }
  };

  useEffect(() => {
    load();
  }, [inboxName]);

  const errorComponent = (
    <InboxThreadListError onReload={() => actions.getInbox()} />
  );

  if (state.isLoading && !state.threads?.length) {
    return (
      <div className="mt-5">
        <Loader />
      </div>
    );
  }

  if (state.apiError && state.pageOffset === 0) return errorComponent;
  if (!state.threads?.length) return <InboxThreadsZeroState />;

  return (
    <InfiniteList
      RowClass={(props) => <InboxRowWrapper {...props} inboxName={inboxName} />}
      itemCount={state.threads?.length}
      itemSize={100}
      heightOffset={heightOffset}
      loadNextPageCallback={() => actions.getInbox()}
      hasNextPage={state.hasNextPage}
      isNextPageLoading={state.isLoading}
      errorRow={state.apiError ? () => errorComponent : null}
    />
  );
};

export default InboxThreadList;
