import React from "react";
import { showChatWithSupport } from "../Support/Intercom";

const AppClientDetails = ({ clientId, clientSecret }) => {
  const [showSecret, setShowSecret] = React.useState(false);

  const onClick = () => {
    setShowSecret(!showSecret);
  };

  return (
    <div>
      <h6 className="mb-2 fw-bold">Client Credentials</h6>
      <div>
        <label htmlFor="exampleFormControlInput1" className="form-label mb-1">
          Client ID
        </label>
        <input className="form-control form-control-sm" value={clientId} />
      </div>
      <div className="mt-2">
        <label htmlFor="exampleFormControlInput1" className="form-label mb-1">
          Client Secret
        </label>
        <div className="input-group input-group-sm mb-3 pt-1">
          <input
            type={showSecret ? "text" : "password"}
            className="form-control"
            value={clientSecret}
          />
          <button
            type="button"
            className="input-group-text cursor-pointer"
            onClick={onClick}
          >
            <i className={`bi-eye${showSecret ? "" : "-slash"}`} />
          </button>
        </div>
      </div>
      <div className="alert alert-light">
        <p className="mb-1">
          <i
            style={{ fontSize: "21px" }}
            className="bi-exclamation-triangle-fill text-dark me-1 fs-15"
          />
          To roll your credentials please message support.
        </p>
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() => showChatWithSupport()}
        >
          Open support
        </button>
      </div>
    </div>
  );
};

export default AppClientDetails;
