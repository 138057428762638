import React from "react";
import { SupportAnchor } from "../lib/ikeono";
import Button, { ButtonSize } from "../components/Button";
import { showChatWithSupport } from "./Intercom";

const AppContact = () => {
  const onClick = async () => {
    showChatWithSupport();
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-column align-items-center gap-3">
          <h6 className="fw-bold mb-0">Ikeono Support</h6>
          <span>
            If the FAQ did not resolve your issue then please contact us.
          </span>
          <div className="d-flex gap-2 align-items-center">
            <Button size={ButtonSize.medium} onClick={onClick}>
              Chat with Support
            </Button>
            <span>－or－</span>
            <SupportAnchor className="text-secondary" />
          </div>
          <span className="fs-14 text-secondary">
            Please note, support is limited on the weekends.
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppContact;
