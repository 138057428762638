/* eslint-disable camelcase */
import React from "react";

import MessageInvoiceCard from "./MessageInvoiceCard";
import MessageWidgetCard from "./MessageWidgetCard";
import SmsCard from "./SmsCard";
import MessageCardDetails from "./MessageCardDetails";
import AttachmentCardFactory from "./AttachmentCardFactory";
import { useState } from "../lib/overmind";
import ReviewRequestCard from "./ReviewRequestCard";
import MessageWorkorderCard from "./MessageWorkorderCard";

const NullCard = () => {
  return null;
};

const MessageCardFactory = ({ idx, bottomRef }) => {
  let CardClass = NullCard;

  const state = useState().activeThread;
  const message = state.messages[idx];

  if (message.meta?.source === "invoice") {
    CardClass = MessageInvoiceCard;
  } else if (message.meta?.source === "website_widget") {
    CardClass = MessageWidgetCard;
  } else if (message.meta?.source === "lightspeed_workorder") {
    CardClass = MessageWorkorderCard;
  } else if (message.meta?.context?.type === "SMSFeedbackMessageConfig") {
    CardClass = ReviewRequestCard;
  } else if (message.body) {
    CardClass = SmsCard;
  }

  const cardJustify = message.is_from_shop ? "justify-content-end" : "";
  const hideCardClass = CardClass === NullCard ? "d-none" : "";

  return (
    <>
      <AttachmentCardFactory message={message} bottomRef={bottomRef} />
      <div
        className={`d-flex flex-column py-2 ${cardJustify} ${hideCardClass}`}
        data-message-id={message.sid}
        data-message-status={message.status}
      >
        <MessageCardDetails message={message}>
          <CardClass message={message} idx={idx} />
        </MessageCardDetails>
      </div>
    </>
  );
};

export default MessageCardFactory;
