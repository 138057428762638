import React from "react";
import DefaultIntegrationCard from "./Cards/DefaultIntegrationCard";
import WarningIntegrationCard from "./Cards/WarningIntegrationCard";
import InstalledIntegrationCard from "./Cards/InstalledIntegrationCard";

const IntegrationsList = ({ integrations }) => {
  if (!integrations) return null;
  return (
    <div className="d-flex flex-wrap gap-4">
      {integrations?.map((integration, i) => {
        let CardClass = DefaultIntegrationCard;
        if (integration.status === "warning") {
          CardClass = WarningIntegrationCard;
        } else if (integration.status === "installed") {
          CardClass = InstalledIntegrationCard;
        }
        return <CardClass key={i} {...integration} />;
      })}
    </div>
  );
};

export default IntegrationsList;
