import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router";
import "./Inbox.css";
import InboxListColumn from "./InboxListColumn";

const InboxContainer = () => {
  return (
    <div className="inbox-wrapper">
      <InboxListColumn />
      <Outlet />
    </div>
  );
};

export default InboxContainer;
