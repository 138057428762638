import React from "react";
import moment from "moment";

import SimpleForm from "../../Form/SimpleForm";
import LabeledField from "../../Form/LabeledField";
import { useActions, useState } from "../../../lib/overmind";
import Loader from "../../Loader";
import Checkbox from "../../Form/Checkbox";
import DateTimeControl from "../../Form/DateTimeControl";
import { post } from "../../../lib/api";
import LightspeedDisconnectedWarning from "./LightspeedDisconnectedWarning";

const LightspeedCreateWorkorderForm = ({
  showUpgrade,
  disabled,
  customerPhoneNumber,
  customerVendorId,
  onSubmit,
  isLoading,
}) => {
  const actions = useActions().lightspeed;
  const state = useState().lightspeed;
  const [initialValues, setInitialValues] = React.useState({
    date_in: moment().format("YYYY-MM-DDTHH:mm"),
    date_due: moment().format("YYYY-MM-DDTHH:mm"),
  });

  React.useEffect(() => {
    actions.getWorkorderStatus();
  }, []);

  React.useEffect(() => {
    isLoading(state.isLoading);
  }, [state.isLoading]);

  const apiSubmitHandler = async (data) => {
    const status = JSON.parse(data.status_id);
    data.status_id = status.id;
    data.status_name = status.name;
    data.status_color = status.html_color;
    data.date_in = moment(data.date_in).toISOString();
    data.date_due = moment(data.date_due).toISOString();

    await post("/shop/{exid}/lightspeed/workorder", {
      customer_id: customerVendorId,
      customer_phone_number: customerPhoneNumber,
      ...data,
    });
    onSubmit && onSubmit();
    setInitialValues({
      date_in: moment().format("YYYY-MM-DDTHH:mm"),
      date_due: moment().format("YYYY-MM-DDTHH:mm"),
      internal_note: "",
      receipt_note: "",
      warranty: false,
      save_parts: false,
      status_id: "",
    });
  };

  if (state.isLoading) return <Loader />;
  if (state.apiData?.meta?.is_status_readonly) {
    return <LightspeedDisconnectedWarning />;
  }

  return (
    <SimpleForm
      className="w-100"
      initialValues={initialValues}
      apiSubmitHandler={apiSubmitHandler}
      hideSubmitButton={showUpgrade}
      resetForm
    >
      <fieldset disabled={disabled}>
        <LabeledField
          label="Lightspeed Status"
          required
          name="status_id"
          id="status_id"
          as="select"
        >
          <option value=""> </option>
          {state.workorderStatusData?.map(({ name, id, html_color }) => {
            return (
              <option key={id} value={JSON.stringify({ name, id, html_color })}>
                {name}
              </option>
            );
          })}
        </LabeledField>
        <div className="d-flex gap-3 mt-2">
          <Checkbox id="warranty" name="warranty">
            Warranty
          </Checkbox>
          <Checkbox id="save_parts" name="save_parts">
            Save Parts
          </Checkbox>
        </div>
        <div className="d-flex gap-3 mb-3">
          <DateTimeControl label="Date In" id="date_in" />
          <DateTimeControl
            label="Due"
            id="date_due"
            min={moment().format("YYYY-MM-DDTHH:mm")}
          />
        </div>
        <LabeledField
          label="Internal Note"
          name="internal_note"
          id="internal_note"
          as="textarea"
          groupClassName="mb-3"
        />
        <LabeledField
          label="Receipt Note"
          name="receipt_note"
          id="receipt_note"
          as="textarea"
          groupClassName="mb-3"
        />
        <div
          className="mt-3 gap-3 align-items-center"
          style={{ display: showUpgrade ? "flex" : "none" }}
        >
          <div>
            <button className="btn btn-primary btn-sm " type="button" disabled>
              Save
            </button>
          </div>
          <span className="fs-14">
            Upgrade today for an easier way to create Workorders!
          </span>
        </div>
      </fieldset>
    </SimpleForm>
  );
};

export default LightspeedCreateWorkorderForm;
