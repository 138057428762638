import React from "react";
import UpgradeAccountButtonV2, {
  PricingPlans,
} from "../../UpgradeAccountButtonV2";

const LightspeedCreateWorkorderUpgrade = ({ hidden }) => {
  if (hidden) return null;
  return (
    <div className="card w-100" style={{ maxWidth: "400px" }}>
      <div
        className="card-header bg-black text-white fw-bolder h5 d-flex gap-2 py-3 text-center align-items-center justify-content-center text-uppercase fs-13"
        style={{ letterSpacing: "3px" }}
      >
        <img src="/sparkle-left.svg" height="25px" />
        Standard feature
        <img src="/sparkle-right.svg" height="25px" />
      </div>
      <div className="card-body text-center">
        <h6 className="text-nowrap fw-bold">
          You discovered a feature upgrade!
        </h6>
        <div className="lead fs-16">
          Create Workorders directly in Lightspeed Retail.
        </div>
        <div className="w-100 d-flex mt-4 justify-content-center">
          <UpgradeAccountButtonV2
            plan={PricingPlans.standard}
            featureName="create lightspeed workorders"
          />
        </div>
      </div>
    </div>
  );
};

export default LightspeedCreateWorkorderUpgrade;
