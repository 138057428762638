import { useParams } from "react-router-dom";
import React from "react";
import OffCanvas, {
  hideOffcanvas,
  toggleOffcanvas,
} from "../components/OffCanvas";
import { useActions, useState } from "../lib/overmind";
import AppClientDetails from "./AppClientDetails";
import OffcanvasSection from "../components/OffCanvas/OffCanvasSection";
import APIResponseError from "../components/APIResponseError";
import Loader from "../components/Loader";
import PartnerAppForm from "./PartnerAppForm";

const PartnerAppDetail = () => {
  const { id } = useParams();
  const state = useState().partner;
  const actions = useActions().partner;

  const getDetail = async () => {
    if (!id) return;
    actions.getDetail(id);
  };

  React.useEffect(() => {
    getDetail();
  }, [id]);

  return (
    <OffCanvas
      datatarget="partner-detail-offcanvas"
      title="Partner Detail"
      bodyClass="p-0 mb-5"
    >
      <Loader className="mt-3" hidden={!state.detailIsLoading} />
      <APIResponseError response={state.detailError} />
      <div hidden={state.detailIsLoading || state.detailError}>
        <OffcanvasSection>
          <div className="border-top mb-3" />
          <AppClientDetails
            clientId={state.detail?.client_id}
            clientSecret={state.detail?.client_secret}
          />
        </OffcanvasSection>
        <OffcanvasSection>
          <PartnerAppForm appId={id} app={state.detail} />
        </OffcanvasSection>
      </div>
    </OffCanvas>
  );
};

export default PartnerAppDetail;
