import React from "react";
import { showTicket } from "../../Support/Intercom";

const statusMap = {
  waiting_on_customer: "danger",
  submitted: "info",
  in_progress: "success",
};

const statusDescription = {
  waiting_on_customer: "We are currently waiting on you.",
  submitted: "Your ticket is currently open.",
  in_progress:
    "We are working on your ticket and will let you know when we're ready.",
};

const SignupStatus = ({ status, statusId, latestMessage, ticketId }) => {
  const theme = statusMap[statusId] || "secondary";

  const onClick = () => {
    showTicket(ticketId);
  };

  return (
    <div
      className={`d-flex gap-3 text-${theme}-emphasis bg-${theme}-subtle border border-${theme}-subtle rounded-2 p-3`}
    >
      <div>
        <div
          className={`bg-${theme} rounded text-white d-flex justify-content-center align-items-center fs-14 mt-1`}
          style={{ height: "35px", width: "35px" }}
        >
          <i className="bi-hourglass mt-1" />
        </div>
      </div>
      <div className="d-flex flex-column">
        <b>{status || "Uknown"}</b>
        <span>{statusDescription[statusId]}</span>
        <div hidden={!latestMessage} className="d-flex flex-column mt-3">
          <b className="mb-2">The latest message for your support ticket</b>
          <div
            className="sms-card sms-card--to-shop d-inline-block text-black"
            style={{ maxWidth: "300px" }}
          >
            <div dangerouslySetInnerHTML={{ __html: latestMessage }} />
          </div>
          <span className="mt-2">
            Please open your ticket and click Continue Conversation.{" "}
            <button
              onClick={onClick}
              className="btn btn-link btn-sm"
              type="button"
            >
              Open ticket
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

const IntercomSignupStatus = ({
  status,
  statusId,
  latestMessage,
  ticketId,
}) => {
  return (
    <div className="d-flex flex-column gap-4">
      <div>
        🎉 Good news! A ticket has been opened to finish the setup of your
        account. Check back here to see the status of your account.
      </div>
      <SignupStatus
        status={status}
        statusId={statusId}
        latestMessage={latestMessage}
        ticketId={ticketId}
      />
    </div>
  );
};

export default IntercomSignupStatus;
