import React from "react";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../lib/date";
import { formatPhoneNumberWithAccountCountry } from "../lib/overmind/account/util";
import ContactActionsRow from "./ContactActionsRow";
import FirstLetterBadge from "./FirstLetterBadge";
import ExternalLink from "../components/ExternalLink";
import Loader from "../components/Loader";
import DetailTable, { TableRow } from "../components/DetailTable";

const ContactDetail = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  dateCreated,
  vendorUrl,
  vendorId,
  vendorSource,
  isLoading,
}) => {
  if (isLoading) return <Loader />;
  const phoneFormatted = formatPhoneNumberWithAccountCountry(phoneNumber);
  const name = `${firstName || ""} ${lastName || ""}`;
  const { pathname } = useLocation();

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-row gap-3">
        <FirstLetterBadge
          style={{ fontSize: "18px", width: "45px", height: "45px" }}
        >
          {firstName || lastName}
        </FirstLetterBadge>
        <div>
          <h5 className="mb-0 fw-bold text-capitalize line-break-anywhere">
            {name}
          </h5>
          <span className="text-secondary">{phoneFormatted}</span>
        </div>
      </div>
      <ContactActionsRow
        phoneNumber={phoneNumber}
        email={email}
        firstName={firstName}
        lastName={lastName}
        vendorId={vendorId}
        vendorSource={vendorSource}
      />
      <div>
        <div className="border-bottom my-3 d-flex flex-row">
          <h6 className="fw-bold me-auto">Details</h6>
          <Link style={{ textDecoration: "none" }} to={`${pathname}/edit`}>
            Edit contact
          </Link>
        </div>
        <DetailTable>
          <TableRow
            title="Name"
            className="text-capitalize line-break-anywhere"
          >
            {name}
          </TableRow>
          <TableRow title="Phone">
            {phoneFormatted || (
              <Link to={`${pathname}/edit`} href="#">
                Add Phone
              </Link>
            )}
          </TableRow>
          <TableRow title="Email">
            {email || (
              <Link to={`${pathname}/edit`} href="#">
                Add Email
              </Link>
            )}
          </TableRow>
          <TableRow title="Created On">{formatDate(dateCreated)}</TableRow>
          <TableRow hidden={!vendorUrl} title="Source">
            <ExternalLink href={vendorUrl}>Open in Point of Sale</ExternalLink>
          </TableRow>
        </DetailTable>
      </div>
    </div>
  );
};

export default ContactDetail;
