/* eslint-disable */

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import { OvermindProvider } from "./lib/overmind";
import { inboxRoutes } from "./Inbox";
import NotFound from "./components/NotFound";
import Payments from "./Payments";
import Settings from "./Settings";
import ForgotPassword from "./Auth/ForgotPassword";
import Campaigns from "./Campaigns";
import { history } from "./lib/history";
import * as auth from "./lib/auth";
import "./scss/custom.scss";
import { useActions, useState as useOvermindState } from "./lib/overmind";
import Loader from "./components/Loader";
import LoginComponent from "./Auth/LoginComponent";
import { initSentry } from "./services/sentry";
import ErrorBoundary from "./components/ErrorBoundary";
import { statusPageUrl, SupportAnchor } from "./lib/ikeono";
import AccountLocked from "./AccountLocked";
import ForcePasswordChange from "./Auth/ForcePasswordChange";
import AcceptTermsOfService from "./AcceptTermsOfService";
import TemplatesContainer from "./Templates/TemplatesContainer";
import LightspeedSaleReceiptFormEmbed from "./components/Lightspeed/LightspeedSaleReceiptFormEmbed";
import BrowserExtensionMessageSideRail from "./BrowserExtensionSiderail.jsx";
import $ from "jquery";
import Contacts from "./Contact";
import TrialExpired from "./Billing/TrialExpired";
import BrowserExtensionCreateInvoice from "./BrowserExtensionCreateInvoice";
import CookieWarning from "./CookieWarning";
import Redirect from "./components/Redirect";
import Signup from "./Signup";
import AccountSetupManager, {
  reachedLastStepKey,
} from "./Signup/AccountSetupManager";
import Logout from "./Logout";
import { closePopup } from "./lib/popup";
import { isBrowserExtensionEmbed } from "./BrowserExtension";
import AccountPastDue from "./Billing/AccountPastDue";
import AscendOauth from "./Ascend/AscendOauth";
import AccountVerifiedModal from "./Signup/AccountVerifiedModal";
import PublicChangePassword from "./ChangePassword/PublicChangePassword";
import { registerServiceWorker } from "./services/notification";
import SpecializedFileDownloadWithPermissionCheck from "./Enterprise/SpecializedFileDownloadWithPermissionCheck";
import LightspeedRSeriesOauthComplete from "./components/Lightspeed/LightspeedRSeriesOauthComplete";
import NPS from "./NPS";
import GoogleOAuthExchange from "./GoogleMyBusiness/Settings/GoogleOAuthExchange.jsx";
import ReviewsEventManager from "./Reviews/ReviewsEventManager.jsx";
import Reports from "./Reports";
import OAuthRequestManager from "./OAuth/OAuthRequestManager.jsx";

initSentry();

const UserActionRequiredApp = ({ children }) => (
  <HistoryRouter history={history}>
    <Routes>
      <Route path="*" element={<App />}>
        <Route path="*" element={children} />
      </Route>
    </Routes>
  </HistoryRouter>
);

const Entry = () => {
  const state = useOvermindState();
  const actions = useActions();
  const [isLoggedIn, setIsLoggedIn] = useState(auth.isLoggedIn());

  useEffect(() => {
    if (!isLoggedIn || state.account.data) return;
    actions.account.getAccount();
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    const windowLocation = window.location.href;
    if (
      !windowLocation.includes("/login") &&
      !windowLocation.includes("/signup") &&
      !windowLocation.includes("/password-reset")
    ) {
      let url = `/login`;
      if (
        !windowLocation.includes("/login") &&
        !windowLocation.includes("redirect_url") &&
        !windowLocation.includes("logout")
      ) {
        url = `/login?redirect_url=${
          window.location.pathname + window.location.search
        }`;
      }
      history.push(url, { replace: true });
    }

    return (
      <HistoryRouter history={history}>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginComponent onLoginSuccess={() => setIsLoggedIn(true)} />
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/signup"
            element={<Signup onSuccess={() => setIsLoggedIn(true)} />}
          />
          <Route path="/password-reset" element={<PublicChangePassword />} />
        </Routes>
      </HistoryRouter>
    );
  }

  if (state.account.apiError) {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column pb-8"
        style={{ height: "80vh" }}
      >
        <img
          src="/Ikeono App Icon.svg"
          style={{ height: "70px", width: "70px" }}
        />
        <div className="d-flex flex-row mt-3 text-center">
          <p className="ms-2 ">
            There was an error loading your profile.
            <br />
            Please try again or contact <SupportAnchor />
          </p>
        </div>
        <div className="d-flex gap-3">
          <button
            className="btn btn-primary btn-sm px-2"
            onClick={() => actions.account.getAccount()}
          >
            Reload
          </button>
          <a
            className="btn btn-sm btn-outline-secondary"
            target="_blank"
            href={statusPageUrl}
          >
            View Status Page
          </a>
        </div>

        <button
          className="btn btn-link mt-2 text-secondary"
          style={{ fontSize: "14px" }}
          onClick={() => auth.logout()}
        >
          Logout
        </button>
      </div>
    );
  }

  if (state.account.isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column pb-8"
        style={{ height: "80vh" }}
      >
        <img
          src="/Ikeono App Icon.svg"
          style={{ height: "100px", width: "100px" }}
        />
        <div className="d-flex flex-row mt-3">
          <Loader />
          <span className="ms-2">Loading profile</span>
        </div>
      </div>
    );
  }

  if (state.user.forcePasswordChange === true) {
    return <ForcePasswordChange />;
  }

  if (
    state.account.incompleteProfile ||
    state.account.pendingAccountVerification
  ) {
    return (
      <HistoryRouter history={history}>
        <Routes>
          <Route
            path="/logout"
            element={
              <Logout
                onLogout={() => {
                  reachedLastStepKey.delete();
                }}
              />
            }
          />
          <Route path="/ascend" element={<AscendOauth />} />
          <Route
            path="/pos/oauth/complete"
            element={<LightspeedRSeriesOauthComplete />}
          />
          <Route path="*" element={<AccountSetupManager />} />
        </Routes>
        <AccountVerifiedModal />
      </HistoryRouter>
    );
  }

  if (state.account.isTrialExpired) {
    return <TrialExpired />;
  }
  if (state.account.isPastDue) {
    return <AccountPastDue />;
  }

  if (state.account.accountLockedMessage) {
    return (
      <UserActionRequiredApp>
        <AccountLocked />
      </UserActionRequiredApp>
    );
  }

  if (!state.account.acceptedToS) {
    return (
      <UserActionRequiredApp>
        <AcceptTermsOfService />
      </UserActionRequiredApp>
    );
  }

  if (window.location.pathname.includes("/auth/oauth")) {
    return (
      <HistoryRouter history={history}>
        <Routes>
          <Route path="/auth/oauth" element={<OAuthRequestManager />} />
        </Routes>
      </HistoryRouter>
    );
  }

  if (isBrowserExtensionEmbed()) {
    return (
      <React.StrictMode>
        <HistoryRouter history={history}>
          <Routes>
            <Route
              path="/login"
              element={
                <LoginComponent onLoginSuccess={() => setIsLoggedIn(true)} />
              }
            />
            <Route
              path="/container/lsreceipt/sms_form"
              element={<LightspeedSaleReceiptFormEmbed />}
            />
            <Route
              path="/v2/chat_component"
              element={<BrowserExtensionMessageSideRail />}
            />
            <Route
              path="/external/payments/new"
              element={<BrowserExtensionCreateInvoice />}
            />
            <Route path="/templates" element={<TemplatesContainer />} />
          </Routes>
        </HistoryRouter>
      </React.StrictMode>
    );
  }

  return (
    <React.StrictMode>
      <HistoryRouter history={history}>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<App />}>
            {inboxRoutes()}
            <Route path="/payments/*" element={<Payments />} />
            <Route path="/contacts/*" element={<Contacts />} />
            <Route path="/campaigns/*" element={<Campaigns />} />
            <Route path="/reports/*" element={<Reports />} />
            <Route
              path="/pos/oauth/complete"
              element={<LightspeedRSeriesOauthComplete />}
            />
            <Route path="/settings/*" element={<Settings />} />
            <Route path="/templates" element={<TemplatesContainer />} />
            <Route
              index
              path="/nps/*"
              element={<NPS useDemo={state.account.isIkeonoAccount} />}
            />
            <Route
              path="/download/:filename"
              element={<SpecializedFileDownloadWithPermissionCheck />}
            />
            <Route path="/login" element={<Redirect to="/inbox/all" />} />
            <Route path="/signup" element={<Redirect to="/inbox/all" />} />
            <Route
              path="/forgot-password"
              element={<Redirect to="/inbox/all" />}
            />

            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path="/google/oauth-exchange"
            element={<GoogleOAuthExchange />}
          />
        </Routes>
      </HistoryRouter>
      <ReviewsEventManager />
    </React.StrictMode>
  );
};

if (window.location.pathname == "/close") {
  closePopup();
} else {
  ReactDOM.render(
    <ErrorBoundary>
      {/* <CookieWarning/> */}
      <OvermindProvider>
        <Entry />
      </OvermindProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
}

registerServiceWorker();
