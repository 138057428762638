/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import APIResponseError from "../components/APIResponseError";
import Loader from "../components/Loader";
import InboxCreateModal, {
  inboxCreateModalId,
} from "../InboxCreate/InboxCreateModal";
import InboxEditModal, {
  inboxEditModalId,
} from "../InboxCreate/InboxEditModal";
import * as overmind from "../lib/overmind";
import InboxListItem from "./InboxListItem";
import InboxListZeroState from "./InboxListZeroState";
import BadgeCount from "../components/BadgeCount";
import InboxReviewListItem from "./InboxReviewListItem";
import { supportedCountries } from "../Reviews";
import InboxCollapseButton from "./InboxCollapseButton";

const InboxList = ({ hidden, isCollapsed, onCollapse }) => {
  if (hidden) return null;
  const state = overmind.useState().inboxes;
  const accountState = overmind.useState().account;
  const actions = overmind.useActions().inboxes;

  useEffect(() => {
    actions.get();
  }, []);

  const AllConversationsBadge = ({ isActive }) => (
    <span className="d-flex">
      <span className="me-auto">All Conversations</span>
      <div>
        <BadgeCount isActive={isActive}>
          {state.locationHasUnreadMessages}
        </BadgeCount>
      </div>
    </span>
  );

  return (
    <div className="d-flex flex-column gap-2 h-100">
      <ul className="nav nav-pills mb-auto">
        <li
          className="fw-bold mb-1 mt-1 px-2 w-100"
          style={{ fontSize: "15px" }}
        >
          Inbox
        </li>
        <li hidden={!state.isLoading} className="nav-item mt-3 w-100">
          <Loader />
        </li>
        <li className="w-100" hidden={state.isLoading}>
          <InboxListItem
            slug="all"
            hasUnreadMessages={state.locationHasUnreadMessages}
          >
            {({ isActive }) => <AllConversationsBadge isActive={isActive} />}
          </InboxListItem>
        </li>
      </ul>
      <ul
        className="nav nav-pills mb-auto"
        hidden={!supportedCountries.includes(accountState.country)}
      >
        <li
          className="fw-bold mb-1 mt-1 px-2 w-100"
          style={{ fontSize: "15px" }}
        >
          Reviews
        </li>
        <li className="w-100" hidden={state.isLoading}>
          <InboxReviewListItem />
        </li>
      </ul>
      <ul className="nav nav-pills mb-auto text-break overflow-scroll">
        <li
          className="fw-bold mb-1 d-flex flex-row px-2 w-100"
          style={{ fontSize: "15px" }}
        >
          <span style={{ flex: 1 }} className="fe-auto">
            Inboxes
          </span>
          <i
            role="button"
            data-bs-toggle="modal"
            data-bs-target={`#${inboxCreateModalId}`}
            className="bi-plus-circle"
          />
        </li>
        <APIResponseError response={state.apiError}>
          <button
            type="button"
            className="btn btn-sm btn-primary mt-2"
            onClick={() => actions.get()}
          >
            Reload
          </button>
        </APIResponseError>
        {state.inboxes?.map(({ title, slug, has_unread_messages }) => {
          return (
            <li className="nav-item w-100" key={slug}>
              <InboxListItem
                slug={slug}
                hasUnreadMessages={has_unread_messages}
                title={title}
                inboxEditModalId={inboxEditModalId}
              />
            </li>
          );
        })}
        {state.inboxes?.length === 0 && !state.apiError && (
          <InboxListZeroState />
        )}
      </ul>
      <div className="flex-grow-1 d-flex align-items-end position-sticky bottom-0 w-100">
        <InboxCollapseButton isCollapsed={isCollapsed} onClick={onCollapse} />
      </div>
      <InboxCreateModal />
      <InboxEditModal />
    </div>
  );
};

export default InboxList;
