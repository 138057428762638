import React from "react";
import * as IntercomSdk from "@intercom/messenger-js-sdk";
import { isBrowserExtensionEmbed } from "../BrowserExtension";
import { get } from "../lib/api";

/* eslint-disable no-undef */
export const showChatWithSupport = () => {
  IntercomSdk.show();
};

export const showTicket = (ticketId) => {
  IntercomSdk.showTicket(ticketId);
};

const IntercomApp = () => {
  const [data, setData] = React.useState();

  const fetchData = async () => {
    const resp = await get("/shop/{exid}/customer-service");
    setData(resp.data);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (!data) return;
    data.show = false;
    data.in_browser_extension = isBrowserExtensionEmbed();
    IntercomSdk.Intercom(data);
  }, [data]);
};

export default IntercomApp;
