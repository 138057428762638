import React from "react";
import SimpleForm from "../components/Form/SimpleForm";
import LabeledField from "../components/Form/LabeledField";

const PartnerAppForm = ({ app }) => {
  const apiSubmitHandler = (values) => {};

  return (
    <SimpleForm initialValues={app} apiSubmitHandler={apiSubmitHandler}>
      <h6 className="fw-bold">App Detail</h6>
      <LabeledField
        label="Name"
        name="name"
        className="form-control"
        required
      />
      <LabeledField
        label="Description"
        name="description"
        as="textarea"
        rows="3"
        className="form-control"
        required
        maxLength={500}
      />
      <LabeledField
        label="Redirect URI"
        name="redirect_uri"
        type="url"
        className="form-control"
        required
      />
    </SimpleForm>
  );
};

export default PartnerAppForm;
