import React from "react";
import { useSearchParams } from "react-router-dom";
import OAuthRequest from "./OAuthRequest";
import { useGet, post } from "../lib/api";
import Loader from "../components/Loader";
import APIResponseError from "../components/APIResponseError";
import { useState } from "../lib/overmind";

const OAuthRequestManager = () => {
  const [authorizeError, setAuthorizeError] = React.useState();
  const [autorizeIsLoading, setAuthorizeIsLoading] = React.useState();
  const [get, isLoading, error, resp] = useGet(false);
  const [searchParams] = useSearchParams();
  const state = useState().account;

  React.useEffect(() => {
    const params = {
      client_id: searchParams.get("client_id"),
    };
    get(`partner/auth/oauth/client`, params);
  }, []);

  const onAuthorize = async () => {
    setAuthorizeIsLoading(true);
    setAuthorizeError(null);
    try {
      const data = {
        account_id: state.accountId,
        client_id: searchParams.get("client_id"),
        state: searchParams.get("state"),
      };
      const postResp = await post("/auth/oauth/authorize", data, null, false);
      console.log("tmp: postResp", postResp);
      window.location = postResp.data.partner_redirect_uri;
    } catch (postError) {
      setAuthorizeError(postError);
    }
    setAuthorizeIsLoading(false);
  };

  if (isLoading || !resp) return <Loader />;
  if (error) return <APIResponseError response={error} />;

  return (
    <OAuthRequest
      isLoading={autorizeIsLoading}
      error={authorizeError}
      onAuthorize={onAuthorize}
      clientNumber={resp.data?.client_number}
      clientName={resp.data?.name}
    />
  );
};

export default OAuthRequestManager;
