import React from "react";
import { Route, Routes } from "react-router-dom";
import FeatureContainer from "../components/FeatureContainer";
import ContactDetailOffCanvas from "./ContactDetailOffCanvas";
import ContactPageHeader from "./ContactPageHeader";
import ContactTable from "./ContactTable";
import FeatureTitle from "../components/FeatureContainer/FeatureTitle";

const Contacts = () => {
  return (
    <FeatureContainer>
      <FeatureTitle
        title="Contacts"
        description="Manage your contacts, add new ones, and start conversations."
      >
        <ContactPageHeader />
      </FeatureTitle>
      <Routes>
        <Route path="/create" element={<ContactTable />} />
        <Route path="/:id/*" element={<ContactTable />} />
        <Route path="/" element={<ContactTable />} />
      </Routes>
      <ContactDetailOffCanvas />
    </FeatureContainer>
  );
};

export default Contacts;
