import { Outlet } from "react-router";
import React, { useEffect } from "react";
import "./App.css";
import SystemMessage from "./components/SystemMessage";
import MainMenuNav from "./MainMenu";
import NotificationPreferenceModal from "./NotificationPreference";
import ChangePasswordModal from "./ChangePassword/ChangePasswordModal";
import "bootstrap/dist/css/bootstrap.min.css";
import { inPortalIframe } from "./lib/environment";
import ToastManager from "./components/Toast/ToastManager";
import { useActions } from "./lib/overmind";
import AccountUpdatesOffCanvas from "./AccountUpdates";
import InboxInvoice from "./Invoice/InboxInvoice";
import ReviewsFeatureUpgradeModal from "./Reviews/Setup/ReviewsFeatureUpgradeModal";
import ReviewSetupModal from "./Reviews/Setup/ReviewsSetupModal";
import BrowserExtensionInstallModal from "./BrowserExtension/BrowserExtensionInstallModal";
import { reachedLastStepKey } from "./Signup/AccountSetupManager";
import AccountEinUpdateModal from "./Account/AccountEinUpdateModal";
import WebchatShowCodeModal from "./Webchat/WebchatPreview/WebchatShowCodeModal";
import WebchatUpgradeModal from "./Webchat/WebchatUpgrade/WebchatUpgradeModal";
import IntercomApp from "./Support/Intercom";
import LightspeedCreateWorkorderModal from "./components/Lightspeed/LightspeedCreateWorkorder/LightspeedCreateWorkorderModal";

const App = () => {
  const actions = useActions().browserExtensionEventBus;

  useEffect(() => {
    actions.checkExtensionIsInstalled();
    reachedLastStepKey.delete();
  }, []);

  return (
    <div className="d-flex flex-column">
      <MainMenuNav hidden={inPortalIframe()} />
      <div
        className="d-flex overflow-scrll"
        style={{
          height: "calc(100vh - 60px)",
        }}
      >
        <div className="flex-grow-1">
          <Outlet />
        </div>
      </div>
      <NotificationPreferenceModal />
      <ChangePasswordModal />
      <ToastManager />
      <AccountUpdatesOffCanvas />
      <InboxInvoice />
      <ReviewsFeatureUpgradeModal />
      <ReviewSetupModal />
      <BrowserExtensionInstallModal />
      <AccountEinUpdateModal />
      <WebchatShowCodeModal />
      <WebchatUpgradeModal />
      <IntercomApp />
      <LightspeedCreateWorkorderModal />
    </div>
  );
};

export default App;
