/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { Formik, Form } from "formik";
import { json } from "overmind";
import Loader from "../Loader";
import Button from "../Button";
import APIResponseError from "../APIResponseError";
import { getRandomString } from "../../lib/stringUtil";
import { captureException } from "../../services/sentry";
import FormSuccess from "./FormSuccess";
import { setNullToString } from "../../lib/object";

/*
children: Can be a react component or a function.  The function is called with the response data
*/
const SimpleForm = ({
  id = getRandomString(),
  apiSubmitHandler,
  apiGetHandler,
  children,
  initialValues,
  hideSubmitButton,
  submitButtonPortalNodeId,
  resetForm,
  submitButtonProps,
  autoComplete,
  disableSuccess,
  className,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  // It's common to receive an Overmind Proxy object for the initial values of a form.  Best to
  // use a vanilla object for the initial values to prevent side effects from happening.
  const [iValues, setIValues] = useState(
    initialValues ? json(initialValues) : null
  );
  const [fetchResponse, setFetchResponse] = useState(null);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitPortalElement, setSubmitButtonElement] = useState(null);
  const successRef = useRef();

  useEffect(() => {
    setSubmitButtonElement(document.getElementById(submitButtonPortalNodeId));
  }, []);

  const getAPIData = async () => {
    if (!apiGetHandler || initialValues || fetchResponse || isLoading) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setError(null);
    setFetchResponse(null);
    try {
      const response = await apiGetHandler();
      setIValues(response?.data);
      setFetchResponse(response);
    } catch (error) {
      console.log(error);
      captureException(error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (fetchResponse) return;
    getAPIData();
  }, []);

  useEffect(() => {
    setIValues(initialValues);

    if (initialValues?.id !== iValues?.id) {
      setApiResponse(null);
    }
  }, [initialValues]);

  useEffect(() => {
    setNullToString(json(iValues));
  }, [iValues]);

  const onSubmit = async (values, actions) => {
    setIsSaving(true);
    setApiResponse(null);
    setShowSuccess(false);

    const valuesClean = values;
    for (const k in valuesClean) {
      if (valuesClean[k] === "") {
        valuesClean[k] = null;
      }
    }

    try {
      const response = await apiSubmitHandler(
        valuesClean ? json(valuesClean) : valuesClean
      );
      setApiResponse(response);
      setShowSuccess(true);
      if (resetForm) {
        actions.resetForm({ values: "" });
        setShowSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setApiResponse(error.response || error);
    }
    setIsSaving(false);
    successRef?.current?.scrollIntoView();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <APIResponseError className="mt-2" response={error?.response} />;
  }

  return (
    <Formik
      initialValues={iValues}
      onSubmit={onSubmit}
      enableReinitialize
      autoComplete={autoComplete}
      onReset={() => {
        setError(null);
        setApiResponse(null);
        setShowSuccess(false);
      }}
    >
      <Form id={id} className={className}>
        {typeof children === "function" ? children(fetchResponse) : children}
        <APIResponseError response={apiResponse} className="mt-3" />
        {submitPortalElement &&
          ReactDOM.createPortal(
            <Button form={id} showLoader={isLoading || isSaving}>
              {submitButtonProps?.children || "Save"}
            </Button>,
            submitPortalElement
          )}
        {!submitPortalElement && (
          <div
            ref={successRef}
            className="d-flex text-end d-flex mt-3 align-items-center"
          >
            <FormSuccess
              hidden={!showSuccess || disableSuccess}
              className={hideSubmitButton ? "w-100 my-2" : ""}
            />
            <Button
              hidden={hideSubmitButton}
              {...submitButtonProps}
              showLoader={isLoading || isSaving}
            >
              {submitButtonProps?.children || "Save"}
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default SimpleForm;
