import React from "react";
import ExternalLink from "../components/ExternalLink";
import { isLoggedIn } from "./auth";

export const supportEmail = "help@ikeono.com";
export const supportPhoneNumber = "+17205490855";
export const privacyPolicyUrl = "https://www.ikeono.com/privacy-policy";
export const termsOfUseUrl = "https://www.ikeono.com/terms-of-use";
export const statusPageUrl = "https://status.ikeono.com";
export const SupportAnchor = (props) => (
  <a
    target="_blank"
    href={`mailto:${supportEmail}`}
    rel="noreferrer"
    {...props}
  >
    {supportEmail}
  </a>
);
export const ContactSupportCta = () => (
  <span>
    Please contact <SupportAnchor /> if you keep experiencing issues.{" "}
    <ExternalLink hidden={!isLoggedIn()} href="/settings/support">
      Open support
    </ExternalLink>
  </span>
);
