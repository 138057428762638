import React from "react";
import SectionNavBar from "../SectionNavBar";

const FeatureContainer = ({ navItems, children }) => {
  return (
    <div className="bg-light pb-3 pb-md-5" style={{ minHeight: "100%" }}>
      <div className="container">
        <SectionNavBar>{navItems}</SectionNavBar>
        {children}
      </div>
    </div>
  );
};

export default FeatureContainer;
