import React from "react";
import PaymentReportCard from "./PaymentReportCard";
import { useState } from "../lib/overmind";
import { formatCurrency } from "../lib/currency";
import DataPointsCard from "../components/DataPointsCard";
import DataPointsList from "../components/DataPointsList";

const PaymentReport = () => {
  const state = useState().payments;

  const conversionRate = Math.round(
    (state.metadata?.conversion_rate || 0) * 100
  );

  const dataPointsList = [
    {
      title: "Highest transaction amount",
      subtitle: "Year to date",
      value: formatCurrency(state.metadata?.highest_transaction),
      icon: "bi-trophy-fill",
    },
    {
      title: "Average transaction amount",
      subtitle: "Year to date",
      value: formatCurrency(state.metadata?.average_transaction),
      icon: "bi-bar-chart-fill",
    },
  ];

  return (
    <div className="d-none d-sm-none d-md-flex gap-4">
      <DataPointsCard>
        {[
          {
            title: "Total payments sent",
            subtitle: "Year to date",
            body: state.metadata?.total_sent,
          },
          {
            title: "Text-to-pay revenue",
            subtitle: "Year to date",
            body: formatCurrency(state.metadata?.total_paid),
          },
          {
            title: "Payment Link Conversion Rate",
            subtitle: "Year to date",
            body: `${conversionRate}%`,
          },
        ]}
      </DataPointsCard>
      <div className="ms-auto">
        <DataPointsList>{dataPointsList}</DataPointsList>
      </div>
    </div>
  );
};

export default PaymentReport;
