import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../lib/api";

import { reportError } from "../../lib/errorHandler";
import APIResponseError from "../APIResponseError";
import Loader from "../Loader";
import LightspeedOauth from "./LightspeedOauth";
import { usePosConnectedEvent } from "../../lib/pos";
import VendOauth from "../Vend/VendOauth";

const LightspeedConnectionCheck = ({ children, useVend = false }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState();

  const load = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await get("/shop/{exid}/lightspeed/connection");
      setResponse(apiResponse);
    } catch (e) {
      reportError(e);
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const navigate = useNavigate();

  usePosConnectedEvent(() => {
    navigate(0);
  });

  if (isLoading) return <Loader />;
  if (error) return <APIResponseError response={error?.response} />;
  if (
    response?.data?.lightspeed?.status === "connected" ||
    response?.data?.vend?.status === "connected"
  ) {
    return children;
  }

  return useVend ? <VendOauth /> : <LightspeedOauth />;
};

export default LightspeedConnectionCheck;
