import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import LightspeedStoreAssignment from "../../components/Lightspeed/LightspeedStoreAssignment";
import LightspeedSpecialOrder from "../../components/Lightspeed/LightspeedSettings/LightspeedSpecialOrder";
import LightspeedWorkorderAutomation from "../../components/Lightspeed/LightspeedWorkorderAutomation";
import SectionHeader from "../../components/SectionHeader";
import LightspeedConnectionCheck from "../../components/Lightspeed/LightspeedConnectionCheck";
import { FeatureTierBadge } from "../../components/FeatureTierBadge";

const LocationLightspeedSettings = () => {
  const params = useParams();

  return (
    <LightspeedConnectionCheck>
      <SectionHeader className="text-capitalize mb-4">
        {params["*"].replaceAll("-", " ")}
        <FeatureTierBadge className="ms-3 " />
      </SectionHeader>
      <Routes>
        <Route
          path="store-assignment"
          element={<LightspeedStoreAssignment />}
        />
        <Route
          path="special-order-message"
          element={<LightspeedSpecialOrder />}
        />
        <Route
          path="workorder-automation"
          element={<LightspeedWorkorderAutomation />}
        />
      </Routes>
    </LightspeedConnectionCheck>
  );
};

export default LocationLightspeedSettings;
