import * as API from "../../api";

export const get = async (params, requestConfig) => {
  const response = await API.get(
    "/shop/{exid}/integration",
    params,
    requestConfig
  );
  return response.data;
};

export const getPosConnectionStatus = async () => {
  const response = await API.get("/shop/{exid}/lightspeed/connection");
  return response.data;
};

export const disconnectApp = async (appId) => {
  const response = API.delete_(`/account/{account_id}/app/${appId}`);
  return response.data;
};
