import React from "react";

const NoAppsWarning = () => {
  return (
    <div>
      <i className="text-secondary">
        Your account currently has no connected accounts.
      </i>
    </div>
  );
};

export default NoAppsWarning;
