import React from "react";
import { useActions, useState } from "../lib/overmind";
import InboxMultiAssignDropdown from "./MultiAssignInboxDropdown";
import { useTooltips } from "../components/Tooltip";

const ActionButton = ({ iconName, ...props }) => (
  <i
    role="button"
    className={`${iconName} text-center flex-grow-1`}
    {...props}
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-title={props.title}
  />
);

const InboxMultiSelectActions = () => {
  useTooltips();
  const state = useState().activeInbox;
  const actions = useActions().activeInbox;

  return (
    <div className="border-bottom" hidden={!state.multiSelectEnabled}>
      <div className="d-flex flex-row py-2 px-3 text-secondary" hidden>
        <ActionButton
          hidden={state.filter?.showClosed}
          iconName="bi-list-check"
          title="Select all conversations"
          onClick={() => actions.selectAllThreads()}
        />
        <ActionButton
          hidden={state.filter?.showClosed}
          iconName="bi-x-square"
          title="Close conversations"
          onClick={() => actions.archiveSelectedThreads()}
        />
        <ActionButton
          hidden={!state.filter?.showClosed}
          iconName="bi-folder"
          title="Open conversations"
          onClick={() => actions.unarchiveSelectedThreads()}
        />
        <ActionButton
          iconName="bi-app-indicator"
          title="Mark unread"
          onClick={() => actions.markSelectedThreadsUnread()}
        />
        <ActionButton
          iconName="bi-app"
          title="Mark read"
          onClick={() => actions.markSelectedThreadsRead()}
        />
        <InboxMultiAssignDropdown />
      </div>
    </div>
  );
};

export default InboxMultiSelectActions;
