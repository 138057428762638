import React from "react";

const DataPoint = ({ title, subtitle, body, hideBorder }) => {
  return (
    <div
      className={`flex-grow-1 ps-4 d-flex flex-column ${
        hideBorder ? "" : "border-end"
      }`}
    >
      <div className="fw-bold fs-15 mb-1">{title}</div>
      <div className="h3 fw-bold mt-auto mb-auto">{body}</div>
      <div className="fs-14 text-secondary fw-semibold">{subtitle}</div>
    </div>
  );
};

const DataPointsCard = ({ children }) => {
  if (!children) throw new Error("Cannot render with null children");
  return (
    <div className="rounded-2 bg-white py-3 shadow-sm d-flex w-100 ">
      {children?.map((dataPoint, idx) => {
        return (
          <DataPoint hideBorder={idx === children.length - 1} {...dataPoint} />
        );
      })}
    </div>
  );
};

export default DataPointsCard;
