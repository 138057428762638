import React, { useEffect, useState } from "react";

import Loader from "../components/Loader";
import * as overmind from "../lib/overmind";
import ConnectedApps from "./ConnectedApps";
import IntegrationsList from "./IntegrationsList";
import integrationsIndex from "./integrationsIndex";
import IntegrationError from "./IntegrationError";

const Integrations = () => {
  const actions = overmind.useActions().integrations;
  const [integrations, setIntegrations] = useState(null);
  const state = overmind.useState();

  useEffect(() => {
    actions.get();
  }, []);

  useEffect(() => {
    state.integrations.integrations?.forEach((i) => {
      const integration = integrationsIndex[i.id];
      if (integration) {
        integration.status = i.status;
      }
    });
    integrationsIndex.browser_extension.status = state.browserExtensionEventBus
      .isExtensionInstalled
      ? "installed"
      : null;
    const integrationVals = Object.keys(integrationsIndex).map(
      (key) => integrationsIndex[key]
    );
    setIntegrations(integrationVals);
  }, [
    state.integrations.integrations,
    state.browserExtensionEventBus.isExtensionInstalled,
  ]);

  if (state.integrations.isLoading) return <Loader />;
  if (state.integrations.apiError) {
    return (
      <IntegrationError
        error={state.integrations.apiError}
        onClick={() => actions.get()}
      />
    );
  }

  return (
    <div className="container mt-3 d-flex flex-column mb-5">
      <div>
        <h6 className="lead mb-3">Integrations</h6>
        <IntegrationsList integrations={integrations} />
      </div>
      <hr className="my-4" />
      <div>
        <h6 className="lead mb-3">Connected Third-party Apps</h6>
        <ConnectedApps apps={state.integrations.connected_apps} />
      </div>
    </div>
  );
};

export default Integrations;
