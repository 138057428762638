import React, { useState } from "react";
import { useActions } from "../lib/overmind";

const ThreadDragAndDrop = ({ children }) => {
  const [showDragActive, setShowDragActive] = useState(false);

  const actions = useActions().activeThread;

  const onDropHandler = (ev) => {
    ev.preventDefault();
    const { files } = ev.dataTransfer;
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/gif" &&
      file.type !== "image/webp" &&
      file.type !== "application/pdf"
    ) {
      alert("Supported media types are PNG, JPEG, GIF, PDF");
      return;
    }
    if (file.size > 8000000) {
      // 8MB
      alert("File is too large. Limit is 8MB");
      return;
    }

    setShowDragActive(false);
    const fr = new FileReader();
    fr.onload = () => {
      actions.updateAttachment({
        type: "imageFileData",
        data: fr.result,
        file,
      });
    };
    fr.readAsDataURL(file);
  };

  const style = {
    height: "100%",
  };

  if (showDragActive) {
    style.border = "1px solid #4287f538";
  }

  return (
    <div
      style={style}
      onDragEnd={() => setShowDragActive(false)}
      onDragLeave={() => setShowDragActive(false)}
      onDragOver={(e) => {
        e.preventDefault();
        setShowDragActive(true);
      }}
      onDrop={onDropHandler}
    >
      {children}
    </div>
  );
};

export default ThreadDragAndDrop;
