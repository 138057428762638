import React from "react";
import GoogleOAuthButton from "../GoogleMyBusiness/Settings/GoogleOAuthButton";
import { ListItem } from "../FeatureUpgrade/SplitPanelFeatureUpgrade";

const GoogleZeroState = () => {
  return (
    <div className="container col-xxl-12 h-100 d-flex justify-content-center align-items-center mt-5">
      <div className="row flex-lg-row-reverse align-items-center">
        <div className="col">
          <h2 className="display-6 fw-bold lh-1 mb-3">
            Start collecting more reviews!
          </h2>
          <p className="lead mb-4">
            Missing out on more reviews means fewer new customers 👀.
          </p>
          <div className="d-flex flex-column gap-3">
            <ListItem>
              Attract new customers by showcasing trusted Google reviews from
              your current customers.
            </ListItem>
            <ListItem>
              Dominate Google Search and boost your local SEO with a high volume
              of reviews.
            </ListItem>
            <ListItem>
              Reply to reviews right in your portal and measure your growth with
              Ikeono.
            </ListItem>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-5">
            <GoogleOAuthButton />
          </div>
        </div>
        <div className="col text-center">
          <img
            className="rounded"
            src="/google-reviews-scroll.gif"
            style={{ maxHeight: "600px", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

export default GoogleZeroState;
