import React, { useRef } from "react";
import { Outlet } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import "./Inbox.css";
import InboxHeader from "./InboxHeader";
import InboxThreadList from "./InboxThreadList";
import InboxThreadOpenCloseTab from "./InboxThreadOpenCloseTab";
import InboxMultiSelectActions from "./InboxMultiSelectActions";
import { useState } from "../lib/overmind";

export const Threads = () => {
  const ref = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const state = useState().activeInbox;

  const { threadId, inboxName } = params;

  React.useEffect(() => {
    if (!inboxName) {
      navigate("/inbox/all");
    }
  }, []);

  return (
    <>
      <div className="border-end" style={{ width: "320px" }}>
        <div ref={ref}>
          <InboxHeader inboxName={inboxName} />
          <InboxThreadOpenCloseTab />
          <InboxMultiSelectActions />
        </div>
        <InboxThreadList
          heightOffset={state.multiSelectEnabled ? 168 : 130}
          includeThreadId={threadId}
          inboxName={inboxName}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Outlet />
      </div>
    </>
  );
};
