import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "../lib/overmind";
import { Tooltip } from "../components/Tooltip";

const ContactPageHeader = () => {
  const navigate = useNavigate();
  const state = useState().contacts;

  return (
    <div className="text-end">
      <Tooltip
        disabled={state.canCreate}
        title="Please go to your Point of Sale to create a new contact."
        placement="left"
      >
        <button
          type="button"
          disabled={!state.canCreate || state.isLoading}
          className="btn btn-primary mt-1 px-3"
          onClick={() => navigate("/contacts/create")}
        >
          <i className="bi-plus-lg me-2" />
          Create Contact
        </button>
      </Tooltip>
    </div>
  );
};

export default ContactPageHeader;
