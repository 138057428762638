import React from "react";
import OvermindDataTable from "../components/DataTable/OvermindDataTable";

const PartnerAppTable = () => {
  return (
    <div className="container mt-3">
      <h5>Partner Apps</h5>
      <OvermindDataTable overmindPropertyKey="partner" />
    </div>
  );
};

export default PartnerAppTable;
