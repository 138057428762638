import React, { useEffect } from "react";
import InboxList from "./InboxList";
import InboxCollapseButton from "./InboxCollapseButton";
import { LocalStorageKey } from "../lib/localStorageKey";
import InboxFolderIcon from "./InboxFolderIcon";
import { useState } from "../lib/overmind";

const key = new LocalStorageKey("ikInboxListIsCollapsed");

const OverallInboxFolderIcon = ({ hidden, onClick }) => {
  const state = useState().inboxes;

  if (hidden) return null;
  return (
    <div role="button" onClick={onClick}>
      <div className="d-flex justify-content-center mt-1">
        <InboxFolderIcon hasUnreadMessages={state.locationHasUnreadMessages} />
      </div>
    </div>
  );
};

const InboxListColumn = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(key.isEnabled());

  useEffect(() => {
    key.set(isCollapsed);
  }, [isCollapsed]);

  return (
    <div
      style={{ width: "250px", minWidth: isCollapsed ? null : "250px" }}
      className={`position-relative border-end bg-light overflow-y-scroll px-2 pt-2 d-none d-md-block ${
        isCollapsed ? "inbox-list-collapsed" : ""
      }`}
    >
      <OverallInboxFolderIcon
        hidden={!isCollapsed}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      />
      <InboxList
        hidden={isCollapsed}
        isCollapsed={isCollapsed}
        onCollapse={() => setIsCollapsed(true)}
      />
      <button
        hidden={!isCollapsed}
        type="button"
        className="btn btn-link text-secondary py-0 p-2 position-absolute bottom-0"
        onClick={() => setIsCollapsed(false)}
        title="Expand the inbox list"
      >
        <i className="bi-box-arrow-right" />
      </button>
    </div>
  );
};

export default InboxListColumn;
