/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Button, { ButtonSize } from "../components/Button";
import { useState } from "../lib/overmind";
import APIResponseError from "../components/APIResponseError";

const OAuthRequest = ({
  isLoading,
  error,
  onAuthorize,
  clientNumber,
  clientName,
}) => {
  const state = useState().account;

  const onCancel = () => {
    window.location.replace("/");
  };

  return (
    <div className="container justify-content-center align-items-center d-flex mt-5 flex-column">
      <div>
        <div className="card" style={{ maxWidth: "55ch" }}>
          <div className="card-body p-5">
            <img
              height="50px"
              className="mb-4"
              src="https://images.squarespace-cdn.com/content/v1/63a3448b4bb6213ae43afb6d/2da25983-dabd-440f-8b14-033cf7ec7aac/SS+-+Logo.png?format=1500w"
            />
            <h4 className="mb-3">Allow access to your Ikeono Account</h4>
            <span>
              If you click the "Authorize Application" button below you will
              give the application{" "}
              <b>
                {clientName} ({clientNumber})
              </b>{" "}
              the rights listed below.
            </span>
            <div className="mt-3">
              <p className="lead fw-bold mb-2">Access Rights</p>
              <ol>
                <li>Send messages from your locations phone number.</li>
                <li>Send review requests from your account.</li>
                <li>
                  View basic account information and locations assocaited with
                  your account.
                </li>
              </ol>
            </div>
            <div className="mt-4 d-flex">
              <Button
                onClick={onAuthorize}
                showLoader={isLoading}
                className="fw-bold px-3"
              >
                Authorize Application
              </Button>
              <button
                type="button"
                className="btn btn-text text-secondary ms-auto fs-18"
                onClick={onCancel}
              >
                No thanks
              </button>
            </div>
            <APIResponseError response={error} className="mt-3" />
          </div>
        </div>
        <div className="mt-2 fs-13">
          Logged in as <b>{state.userEmail}</b>, at <b>{state.shopName}</b>
        </div>
      </div>
    </div>
  );
};

export default OAuthRequest;
