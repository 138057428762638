const makeTableData = (apps) => {
  const rows = apps.map((app) => ({
    data_bs_target: "#partner-detail-offcanvas",
    id: app.id,
    url: `/settings/partner/${app.id}`,
    columns: [
      {
        value: app.name,
      },
      {
        value: app.description,
      },
      {
        value: app.redirect_uri,
        className: "text-lowercase",
      },
    ],
  }));

  return {
    headers: ["Name", "Description", "Redirect URI"],
    rows,
  };
};

export const get = async ({ state, effects }) => {
  state.partner.isLoading = true;
  try {
    const data = await effects.partner.get(state.account.accountId);
    state.partner.data = data?.data;
    state.partner.tableData = makeTableData(state.partner.data);
  } catch (error) {
    state.partner.responseError = error;
  }
  state.partner.isLoading = false;
};

export const getDetail = async ({ state, effects }, id) => {
  console.log("tmp: state.partner.data", state.partner.data);

  state.partner.detailIsLoading = true;
  state.partner.detailError = null;
  state.partner.detail = null;

  try {
    const data = await effects.partner.get(state.account.accountId, id);
    state.partner.detail = data.data;
  } catch (error) {
    state.partner.detailError = error;
  }

  state.partner.detailIsLoading = false;
};

export const update = async ({ state, effects }, { appId, app }) => {
  const data = await effects.partner.update(
    state.account.accountId,
    appId,
    app
  );
  const { users } = state.users.data;
  state.users.data.users = users.map((localUser) => {
    if (localUser.id === appId) return data.data;
    return localUser;
  });
  state.users.tableData = makeTableData(state.users.data.users);
};
