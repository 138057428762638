import React from "react";
import Button, { ButtonVarint } from "../../components/Button";

const GoogleDisconnect = () => {
  return (
    <div className="card-body p-4">
      <h4 className="fw-bold">Disconnect</h4>
      <p className="lead">Disconnect your Google account.</p>
      <Button
        onClick={() => {
          // eslint-disable-next-line no-undef
          Intercom("showArticle", 10743757);
        }}
        variant={ButtonVarint.secondaryOutline}
      >
        Disconnect
      </Button>
    </div>
  );
};

export default GoogleDisconnect;
