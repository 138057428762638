/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import PushNotificationSetup, {
  PushNotificationDetail,
} from "./PushNotificationSetup";
import IntegrationSetup from "./IntegrationSetup";
import SplitDetailContainer from "./components/SplitDetailContainer";
import * as overmind from "../lib/overmind";
import CompleteBusinessProfile, {
  CompleteBusinessProfileDetail,
} from "./CompleteBusinessProfile";
import BrowserExtensionSetup from "./BrowserExtensionSetup";
import ToastManager from "../components/Toast/ToastManager";
import { LocalStorageKey } from "../lib/localStorageKey";
import ProgressBar from "../components/ProgressBar";
import BrowserExtensionSetupDetail from "./BrowserExtensionSetup/BrowserExtensionSetupDetail";
import { useMixpanel } from "../services/mixpanel";
import SetupNavigation from "./components/SetupNavigation";
import IntegrationDetail from "./IntegrationSetup/IntegrationDetail";
import PendingAccountVerification from "./PendingAccountVerification";
import { isMobileDevice } from "../lib/device";

const steps = {
  completeProfile: {
    title: "Complete Business Profile",
    component: CompleteBusinessProfile,
    detailComponent: <CompleteBusinessProfileDetail />,
    hideContinueButton: true,
    trackingName: "Business Profile",
  },
  pushNotification: {
    title: "Never miss a beat",
    component: PushNotificationSetup,
    detailComponent: <PushNotificationDetail />,
    trackingName: "Push Notification",
  },
  integrationSetup: {
    title: "Connect your POS",
    component: IntegrationSetup,
    detailComponent: <IntegrationDetail />,
    trackingName: "Integrations",
  },
  browserExtensionSetup: {
    title: "Enhance your experience",
    component: BrowserExtensionSetup,
    detailComponent: <BrowserExtensionSetupDetail />,
    trackingName: "Browser Extension",
  },
  pendingAccountVerification: {
    title: "Almost ready to text!",
    component: PendingAccountVerification,
    showLogout: true,
    maxWidth: "600px",
    trackingName: "Pending Account Verification",
  },
};

export const reachedLastStepKey = new LocalStorageKey(
  "ikAccountSetup__reachedLastStep"
);

const AccountSetupManager = () => {
  const actions = overmind.useActions();
  const state = overmind.useState();
  const [stepArray, setStepArray] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (stepArray?.length && currentStepIndex >= stepArray.length - 1) {
      reachedLastStepKey.set(true); // Will hit the last page
    }
  }, [currentStepIndex]);

  const buildSteps = async () => {
    const computedSteps = [];

    if (state.account.incompleteProfile) {
      computedSteps.push(steps.completeProfile);
    }

    if (!reachedLastStepKey.get()) {
      await actions.pushNotification.refreshStatus();

      if (state.account.posSystem === "standalone") {
        computedSteps.push(steps.integrationSetup);
      }

      if (state.pushNotification.status === "prompt" && !isMobileDevice()) {
        computedSteps.push(steps.pushNotification);
      }
    }

    computedSteps.push(steps.pendingAccountVerification);
    setStepArray(computedSteps);
  };

  useEffect(() => {
    buildSteps();
  }, []);

  if (!stepArray?.length) return null;

  const onComplete = (props) => {
    const currentStep = stepArray[currentStepIndex];

    mixpanel.track(`complete ${currentStep.trackingName}`, {
      action: props?.action,
      "pos system": state.account.posSystem,
    });

    setCurrentStepIndex(currentStepIndex + 1);
  };

  const stepValue = 100 / (stepArray?.length || 1);
  const percentage = (currentStepIndex + 1) * stepValue;
  const step = stepArray[currentStepIndex];

  return (
    <SplitDetailContainer
      detailComponent={step.detailComponent}
      showLogout={step.showLogout}
    >
      <div className="d-flex flex-column justify-content-center align-items-center ">
        <div
          className="mt-2 mt-md-5 mb-auto px-3 px-md-0"
          style={{ maxWidth: step.maxWidth || "420px" }}
        >
          <h3 className="fw-bold mb-2 mb-lg-3">{step.title}</h3>
          <step.component onComplete={onComplete} />
        </div>
        <SetupNavigation
          steps={stepArray}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          hideContinueButton={percentage >= 100 || step.hideContinueButton}
        />
        <ToastManager />
        <ProgressBar
          hidden={percentage >= 100}
          className="position-absolute d-none d-md-flex"
          style={{ left: "30px", bottom: "30px" }}
          percentage={percentage}
        />
      </div>
    </SplitDetailContainer>
  );
};

export default AccountSetupManager;
