import React from "react";

const NoLightspeedContactWarning = ({ hidden }) => {
  if (hidden) return null;
  return (
    <div className="alert alert-light d-flex gap-3">
      <i className="bi-cone text-warning fs-18" />
      <div>
        <b>No associated Lightspeed contact</b>
        <div>
          This conversation was created without an attached Lightspeed contact.
          In order to associate a contact please delete this conversation and
          start a new one.
        </div>
      </div>
    </div>
  );
};

export default NoLightspeedContactWarning;
