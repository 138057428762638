import React from "react";
import APIResponseError from "../components/APIResponseError";

const IntegrationError = ({ error, onClick }) => {
  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <div>
        <APIResponseError response={error} />
      </div>
      <div>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onClick}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default IntegrationError;
