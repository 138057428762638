import React from "react";
import { useState } from "../../lib/overmind";
import IntercomApp from "../../Support/Intercom";
import IntercomSignupStatus from "./IntercomSignupStatus";

const PendingAccountVerificationDetail = () => {
  const state = useState().account;
  if (!state.setupStatus) return null;

  const intercomStatus = state.setupStatus.intercom_status;

  return (
    <div
      className="d-flex flex-column m-auto mt-4 gap-3"
      style={{ maxWidth: "600px" }}
    >
      <IntercomSignupStatus
        status={intercomStatus?.status}
        statusId={intercomStatus?.status_id}
        latestMessage={intercomStatus.latest_message}
        ticketId={state.setupStatus?.intercom_signup_support_ticket_id}
      />
      <div className="d-flex gap-4">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            // eslint-disable-next-line no-undef
            Intercom(
              "showTicket",
              state.setupStatus?.intercom_signup_support_ticket_id
            );
          }}
        >
          View Ticket
        </button>
      </div>
      <IntercomApp />
    </div>
  );
};

export default PendingAccountVerificationDetail;
