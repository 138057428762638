import * as api from "../../api";

export const get = async (accountId, appId) => {
  const response = await api.get(`/account/${accountId}/partner/app/${appId}`);
  return response.data;
};

export const update = async (accountId, appId, app) => {
  const response = await api.put(
    `/account/${accountId}/partner/app/${appId}?sleep=3`,
    app
  );
  return response.data;
};
