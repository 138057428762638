import React from "react";
import { Link } from "react-router-dom";

const LightspeedDisconnectedWarning = () => {
  return (
    <div>
      <b>Heads up!</b>
      <div>Before creating a workorder you need to connect to Lightspeed.</div>
      <Link
        className="btn btn-primary btn-sm mt-3"
        to="/settings/location/lightspeed-r-series/store-assignment"
      >
        <i className="bi-hand-index me-1" /> Click here to get started!
      </Link>
    </div>
  );
};

export default LightspeedDisconnectedWarning;
