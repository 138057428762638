import React from "react";

const InboxCollapseButton = ({ onClick }) => {
  return (
    <div className="position-sticky bottom-0 d-flex justify-content-end bg-light w-100">
      <button
        type="button"
        className="btn btn-link text-secondary py-0 p-2"
        onClick={onClick}
        title="Collapse the inbox list"
      >
        <i className="bi-box-arrow-left" />
      </button>
    </div>
  );
};

export default InboxCollapseButton;
