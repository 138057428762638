import React from "react";
import { Route, Routes } from "react-router-dom";
import PartnerAppTable from "./PartnerAppTable";
import PartnerAppDetail from "./PartnerAppDetail";
import { useActions } from "../lib/overmind";

const index = () => {
  const actions = useActions().partner;
  React.useEffect(() => {
    actions.get();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/*" element={<PartnerAppTable />} />
      </Routes>
      <PartnerAppDetail />
    </>
  );
};

export default index;
